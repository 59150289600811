import React, { useEffect, useState, useContext } from 'react';
import { Layout, SEO, TouMingBaiBtn_updown } from "@/components/index"
import { headerBg, nebulaIcon } from '@/images/experience/index'
import style from './index.module.less'
import {
  AdaLogo,
  AirLogo,
  LightLogo
} from '@/images/activation/index'
import { icon_get_it_on_google_2, GoLink } from '@/images/index'
import useGa from "@/hooks/useGa"
import { useIntl } from 'react-intl';
import useNavigateLink from '@/hooks/useNavigateLink'
import GoOtaSvg from '@/images/SVG/select.svg'
import { getOtaVesion } from '@/services/request.js'
import getCountry from '@/utils/getCountry.js'
import { useInView } from "react-intersection-observer"
import {ota_region_go_en} from '@/utils/regions.js'


const Index = () => {
  const intl = useIntl()
  const [tabIndex, setTabIndex] = useState('air2ultra')
  const [airStable, setAirStabel] = useState(false)
  const [airBeta, setAirBeta] = useState(false)
  const [air2Stable, setAir2Stabel] = useState(false)
  const [air2Beta, setAir2Beta] = useState(false)
  const [air2ultraStable, setAir2UltraStabel] = useState(false)
  const [air2ultraBeta, setAir2UltraBeta] = useState(false)
  const [headerRef, headerRefInView] = useInView({ threshold: 0 });
  const [bodyRef, bodyRefInView] = useInView({ threshold: 0 });
  const region = getCountry()
  const update_air_arr=['air','air2','air2ultra']
  const otaLink = ota_region_go_en.includes(region) ? '' : `/${region}`

  const stateSelect = {
    'air2ultra-stable': air2ultraStable,
    'air2ultra-beta': air2ultraBeta,
    'air2-stable': air2Stable,
    'air2-beta': air2Beta,
    'air-stable': airStable,
    'air-beta': airBeta,

  }

  const tabList = [
    {
      type: 'air2ultra',
      text: "Air 2 Ultra"
    },
    {
      type: 'air2',
      text: "Air2/Air2 Pro"
    },
    {
      type: 'air',
      text: "Air"
    },
    {
      type: 'light',
      text: "Light"
    },
    {
      type: "ada",
      text: "Adapter"
    }
  ]
  const updateList = {
    air2ultra: [
      {
        title: intl.formatMessage({ id: "activation.download.title", defaultMessage: "For Android Users" }),
        desc: intl.formatMessage({ id: "activation.download.acti.nreal.app.air2ultra", defaultMessage: "For XREAL Air 2 Ultra users with compatible Android phones, we recommend using the Nebula App to update the glasses" }),
        logo: "",
        btnText: intl.formatMessage({ id: "download_android", defaultMessage: "Download for Android" }),
        link: "https://play.google.com/store/apps/details?id=ai.nreal.nebula.universal",
        cate: 'support_update_air2ultra_down_nebula'
      },
      {
        title: intl.formatMessage({ id: "activation.other.title", defaultMessage: "For Other Users" }),
        desc: intl.formatMessage({ id: "activation.other.subtitle1.air2ultra", defaultMessage: "Please update your XREAL Air 2 Ultra through the link below" }),
        btnText1: intl.formatMessage({ id: "support.update.other.link.stable", defaultMessage: 'Opt for the stable firmware version for a reliable update.' }),
        btnText2: intl.formatMessage({ id: "support.update.other.link.beta", defaultMessage: 'Opt for the beta version for the latest features.' }),
        link1: `${process.env.OTA_URL}${otaLink}${process.env.OTA_URL_AIR2ULTRA_UPGRADE}?version=1`,
        link2: `${process.env.OTA_URL}${otaLink}${process.env.OTA_URL_AIR2ULTRA_UPGRADE}?version=2`,
        cate: 'support_update_air2ultra_update'
      },
    ],
    air: [
      {
        title: intl.formatMessage({ id: "activation.download.title", defaultMessage: "For Android Users" }),
        desc: intl.formatMessage({ id: "activation.download.acti.nreal.app", defaultMessage: "For XREAL Air users with compatible Android phones, we recommend using the Nebula App to update the glasses" }),
        logo: "",
        btnText: intl.formatMessage({ id: "download_android", defaultMessage: "Download for Android" }),
        link: "https://play.google.com/store/apps/details?id=ai.nreal.nebula.universal",
        cate: 'support_update_air_down_nebula'
      },
      {
        title: intl.formatMessage({ id: "activation.other.title", defaultMessage: "For Other Users" }),
        desc: intl.formatMessage({ id: "activation.other.subtitle1" }),
        btnText1: intl.formatMessage({ id: "support.update.other.link.stable", defaultMessage: 'Opt for the stable firmware version for a reliable update.' }),
        btnText2: intl.formatMessage({ id: "support.update.other.link.beta", defaultMessage: 'Opt for the beta version for the latest features.' }),
        link1: `${process.env.OTA_URL}${otaLink}${process.env.OTA_URL_AIR_FORGAME}?version=1`,
        link2: `${process.env.OTA_URL}${otaLink}${process.env.OTA_URL_AIR_FORGAME}?version=2`,
        cate: 'support_update_air_update'
      },
    ],
    light: [
      {
        title: intl.formatMessage({ id: "activation.all" }),
        desc: intl.formatMessage({ id: "activation.other.subtitle2" }),
        logo: LightLogo,
        link: `${process.env.OTA_URL}${otaLink}${process.env.OTA_URL_X}`,
        cate: 'support_update_light_active',
        btnText: intl.formatMessage({ id: "activation.other.x" }),
      }
    ],
    ada: [
      {
        title: intl.formatMessage({ id: "activation.all" }),
        desc: intl.formatMessage({ id: "activation.other.subtitle3" }),
        logo: AdaLogo,
        link: `${process.env.OTA_URL}${otaLink}${process.env.OTA_URL_ADA_UPGRADE}`,
        cate: 'support_update_adapter_update',
        btnText: intl.formatMessage({ id: "activation.gameuser.ada" })
      }
    ],
    air2: [
      {
        title: intl.formatMessage({ id: "activation.download.title", defaultMessage: "For Android Users" }),
        desc: intl.formatMessage({ id: "activation.download.acti.nreal.app.air2", defaultMessage: "For XREAL Air 2/Air 2 Pro users with compatible Android phones, we recommend using the Nebula App to update the glasses" }),
        logo: "",
        btnText: intl.formatMessage({ id: "download_android", defaultMessage: "Download for Android" }),
        link: "https://play.google.com/store/apps/details?id=ai.nreal.nebula.universal",
        cate: 'support_update_air2_down_nebula'
      },
      {
        title: intl.formatMessage({ id: "activation.other.title", defaultMessage: "For Other Users" }),
        desc: intl.formatMessage({ id: "activation.other.subtitle1.air2", defaultMessage: "Please update your XREAL Air 2/Air 2 Pro through the link below" }),
        btnText1: intl.formatMessage({ id: "support.update.other.link.stable", defaultMessage: 'Opt for the stable firmware version for a reliable update.' }),
        btnText2: intl.formatMessage({ id: "support.update.other.link.beta", defaultMessage: 'Opt for the beta version for the latest features.' }),
        link1: `${process.env.OTA_URL}${otaLink}${process.env.OTA_URL_AIR2_UPGRADE}?version=1`,
        link2: `${process.env.OTA_URL}${otaLink}${process.env.OTA_URL_AIR2_UPGRADE}?version=2`,
        cate: 'support_update_air2_update'
      },
    ],
  }


  useEffect(() => {
    if (window.location.href.includes('air2')) {
      setTabIndex('air2')
    }else if (window.location.href.includes('air')) {
      setTabIndex('air')
    } else if (window.location.href.includes('light')) {
      setTabIndex('light')
    } else if (window.location.href.includes('ada')) {
      setTabIndex('ada')
    }


    //判断air和air2 pro 是否有正式版和稳定版
    getOtaVesion().then(res => {
      let { code, data } = res.data
      if (code == 200) {
        const result = data.subset
        const foundP55 = result.find(element => element.dictKey === 'P55F');
        const foundAir = result.find(element => element.dictKey === 'Air');
        const foundUltra = result.find(element => element.dictKey === 'Air 2 Ultra');

        if (!!foundUltra) {
          if (foundUltra.dictValue.includes('1')) {
            setAir2UltraStabel(true)
          }
          if (foundUltra.dictValue.includes('2')) {
            setAir2UltraBeta(true)
          }

        }

        if (!!foundP55) {
          if (foundP55.dictValue.includes('1')) {
            setAir2Stabel(true)
          }
          if (foundP55.dictValue.includes('2')) {
            setAir2Beta(true)
          }
        }

        if (!!foundAir) {
          if (foundAir.dictValue.includes('1')) {
            setAirStabel(true)
          }
          if (foundAir.dictValue.includes('2')) {
            setAirBeta(true)
          }
        }

      }

    }).catch(err => {
      console.log(err)
    })

  }, [])

  const getLinkUrl = (link) => {
    const url = useNavigateLink(link)
    return url
  }

  return (
    <Layout menuBlack={!headerRefInView && bodyRefInView ? true : false}>
      <SEO page="Update" />
      <div className={style.header} ref={headerRef} style={{ backgroundImage: `url(${headerBg})` }}>
        {intl.formatMessage({ id: "support_product_update", defaultMessage: "Firmware Update" })}
      </div>
      <div className={style.content} ref={bodyRef}>
        <div className={style.tab}>
          <div className={style.tabList}>
            {tabList.map((item, index) => (
              <div className={style.tabListItem} key={item.text}
                style={{
                  color: tabIndex == item.type ? 'rgba(0, 0, 0, 0.8)' : "#fff",
                  background: tabIndex == item.type ? '#fff' : 'transparent'
                }}
                onClick={() => setTabIndex(item.type)}
              >
                {item.text}
              </div>
            ))}
          </div>

        </div>
        <div className={style.contentBox}>

          <div className={style.contentBoxItem} key={`updateItem${updateList[tabIndex][0].btnText}`}>
            <div className={style.contentBoxItemTitle}>
              {updateList[tabIndex][0].title}
            </div>
            <div className={style.contentBoxItemDesc}>{updateList[tabIndex][0].desc}</div>
            {(update_air_arr.includes(tabIndex)) ?
              <div className={style.contentBoxItemAndroid}>
                <a href={getLinkUrl(`/support/${tabIndex}`)}>{intl.formatMessage({ id: "activation.download.abiliti.phone", defaultMessage: "Check your phone compatibility" })}<GoOtaSvg /></a>
                <img src={nebulaIcon} className={style.contentBoxItemAndroidImg} />
                {/* <img src={icon_get_it_on_google_2}  className={style.contentBoxItemAndroidGoogle}/> */}
              </div> :
              <img src={updateList[tabIndex][0].logo} />
            }

            <TouMingBaiBtn_updown onClick={() => {
              useGa('support', updateList[tabIndex][0].cate, 'Click')
              window.open(updateList[tabIndex][0].link)
            }}>
              <div className={style.contentBoxItemBtn} style={{ padding: (update_air_arr.includes(tabIndex)) ? '0 20px' : "10px 20px" }}>
                {(update_air_arr.includes(tabIndex)) ? <img style={{ width: "130px" }} src={icon_get_it_on_google_2} /> : updateList[tabIndex][0].btnText}
              </div>
            </TouMingBaiBtn_updown>
          </div>

          {/* air air2 airpro 升级正式和测试版 */}
          {(update_air_arr.includes(tabIndex)) &&
            <div className={style.contentBoxItem}>
              <div className={style.contentBoxItemTitle}>
                {updateList[tabIndex][1].title}
              </div>
              <div className={style.contentBoxItemDesc}
                style={{ marginBottom: "60px" }}
              >{updateList[tabIndex][1].desc}</div>
              {stateSelect[`${tabIndex}-stable`] &&
                <a className={style.updateEntry} target="_blank" href={updateList[tabIndex][1].link1}
                  onClick={() => { useGa('support', updateList[tabIndex][1].cate + 'stable', 'Click') }}
                > {updateList[tabIndex][1].btnText1} <GoOtaSvg /></a>}
              {stateSelect[`${tabIndex}-beta`] &&
                <a className={style.updateEntry} target="_blank" href={updateList[tabIndex][1].link2}
                  onClick={() => { useGa('support', updateList[tabIndex][1].cate + 'beta', 'Click') }}
                > {updateList[tabIndex][1].btnText2} <GoOtaSvg /></a>
              }

            </div>
          }

        </div>
      </div>
    </Layout>
  )
}

export default Index;